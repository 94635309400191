<template>
    <div class="report-select-reason">
        <StackRouterHeaderBar :leftButton="'close'" />
        <div class="top">
            <h1 class="title m-b-12" v-html="title" />
            <p v-if="type === 'user'" class="desc">
                <span class="f-bold">{{ target.profile.nickname }}</span
                >님의 프로필에 어떤 문제가 있나요?
            </p>
        </div>

        <ul v-if="reasons.length > 0" class="reasons m-t-40">
            <li class="reason" v-for="reason in reasons" :key="reason.id" @click="onClickReason(reason)">
                <div class="content">
                    <span class="name">{{ reason.name }}</span>
                    <span v-if="reason.detail" class="detail">{{ reason.detail }}</span>
                </div>
                <i class="material-icons">chevron_right</i>
            </li>
        </ul>
    </div>
</template>

<script>
import commonService from '@/services/common'
import userService from '@/services/user'
import agentService from '@/services/agent'

export default {
    name: 'ReportSelectReasonPage',
    props: {
        type: {
            type: String,
            required: true,
            validator: function (value) {
                return value === 'user' || value === 'agent'
            },
        },
        target: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        reasons: [],
    }),
    computed: {
        title() {
            if (this.type === 'user') return this.$translate('REPORT_PROFILE')
            if (this.type === 'agent') return this.$translate('REPORT_AGENTS')

            return ''
        },
    },
    methods: {
        async onClickReason(reason) {
            try {
                this.$loading(true)
                let reportId

                if (this.type === 'user') {
                    const { report_user: ru } = await userService.reportUser.create(this.target.id, {
                        reason_id: reason.id,
                    })
                    reportId = ru.id
                } else if (this.type === 'agent') {
                    const { report_agent: ra } = await agentService.reportAgent.create(
                        this.target.agent_id || this.target.id,
                        {
                            reason_id: reason.id,
                        },
                    )
                    reportId = ra.id
                }

                this.$stackRouter.push({
                    name: 'ReportSubmitPage',
                    props: { reason, target: this.target, type: this.type, reportId },
                })
            } catch (e) {
                console.error(e)
            } finally {
                this.$loading(false)
            }
        },
        async loadReasons() {
            try {
                this.$loading(true)
                const { reasons } = await commonService.reportReasons(this.type === 'user' ? 'profile' : 'agent')
                this.reasons = [...reasons]
            } catch (e) {
                this.$toast.error(e)
            } finally {
                this.$loading(false)
            }
        },
    },
    mounted() {
        this.loadReasons()
    },
}
</script>

<style lang="scss" scoped>
.report-select-reason {
    .top {
        margin-left: 16px;
    }
    .title {
        font-size: 24px;
        color: black;
        font-weight: 500;

        @include f-medium;
    }

    .desc {
        font-size: 14px;
        color: $grey-08;
    }

    .reasons {
        padding: 0 16px;

        .reason {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 12px;
            background-color: $grey-01;
            margin-bottom: 12px;
            padding: 14px 16px;

            .material-icons {
                color: $grey-04;
            }
        }

        .content {
            justify-content: center;
            @include flex;

            .name {
                font-size: 18px;
                color: black;

                @include f-medium;
            }

            .detail {
                font-size: 13px;
                color: $grey-07;
                margin-top: 8px;
            }
        }
    }
}
</style>
